import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [{
		path: '/',
		redirect: '/index', // 重定向，输入/的时候直接进入首页
		meta: {
			isShow: true,
			title: '品牌之家 品牌榜 | 十大品牌及生活服务排行榜大数据服务平台',
			content: {
				keywords: '品牌之家,排行榜,品牌,名牌,排名,品牌排行,品牌网,十大品牌,品牌百科,什么牌子好',
				description: '品牌之家（zgall.com）是一个综合性的品牌排行榜和生活知识平台，专注于提供详尽的品牌信息、生活指南和知识性文章。网站通过大数据、人工智能、GPT等一系列算法生成排行榜，帮助用户做出明智的品牌选择，从而提升人们的生活质量。'
			}
		}
	},
	{
		path: '/index',
		name: 'index',
		component: () => import('../views/index/index.vue'),
		meta: {
			isShow: true,
			title: '品牌之家 品牌榜 | 十大品牌及生活服务排行榜大数据服务平台',
			content: {
				keywords: '品牌之家,排行榜,品牌,名牌,排名,品牌排行,品牌网,十大品牌,品牌百科,什么牌子好',
				description: '品牌之家（zgall.com）是一个综合性的品牌排行榜和生活知识平台，专注于提供详尽的品牌信息、生活指南和知识性文章。网站通过大数据、人工智能、GPT等一系列算法生成排行榜，帮助用户做出明智的品牌选择，从而提升人们的生活质量。'
			}
		}
	},
	{
		path: '/category',
		name: 'category',
		component: () => import('../views/category/category.vue'),
		meta: {
			isShow: true,
			title: '品牌分类',
			content: {
				keywords: '品牌之家,排行榜,品牌,名牌,排名,品牌排行,品牌网,十大品牌,品牌百科,什么牌子好',
				description: '品牌之家（zgall.com）是一个综合性的品牌排行榜和生活知识平台，专注于提供详尽的品牌信息、生活指南和知识性文章。网站通过大数据、人工智能、GPT等一系列算法生成排行榜，帮助用户做出明智的品牌选择，从而提升人们的生活质量。'
			}
		}

	},
	{
		path: '/wiki',
		name: 'wiki',
		component: () => import('../views/wiki/wiki.vue'),
		meta: {
			isShow: true,
			title: '生活排品牌之家 品牌榜 | 十大品牌及生活服务排行榜大数据服务平台行',
			content: {
				keywords: '品牌之家,排行榜,品牌,名牌,排名,品牌排行,品牌网,十大品牌,品牌百科,什么牌子好',
				description: '品牌之家（zgall.com）是一个综合性的品牌排行榜和生活知识平台，专注于提供详尽的品牌信息、生活指南和知识性文章。网站通过大数据、人工智能、GPT等一系列算法生成排行榜，帮助用户做出明智的品牌选择，从而提升人们的生活质量。'
			}
		}
	},
	{
		path: '/life',
		name: 'life',
		component: () => import('../views/life/life.vue'),
		meta: {
			isShow: true,
			title: '生活排行',
			content: {
				keywords: '品牌之家,排行榜,品牌,名牌,排名,品牌排行,品牌网,十大品牌,品牌百科,什么牌子好',
				description: '品牌之家（zgall.com）是一个综合性的品牌排行榜和生活知识平台，专注于提供详尽的品牌信息、生活指南和知识性文章。网站通过大数据、人工智能、GPT等一系列算法生成排行榜，帮助用户做出明智的品牌选择，从而提升人们的生活质量。'
			}
		}
	},
	{
		path: '/service',
		name: 'service',
		component: () => import('../views/service/service.vue'),
		meta: {
			isShow: true,
			title: '品牌之家 品牌榜 | 十大品牌及生活服务排行榜大数据服务平台',
			content: {
				keywords: '品牌之家,排行榜,品牌,名牌,排名,品牌排行,品牌网,十大品牌,品牌百科,什么牌子好',
				description: '品牌之家（zgall.com）是一个综合性的品牌排行榜和生活知识平台，专注于提供详尽的品牌信息、生活指南和知识性文章。网站通过大数据、人工智能、GPT等一系列算法生成排行榜，帮助用户做出明智的品牌选择，从而提升人们的生活质量。'
			}
		}
	},
	{
		path: '/ranking',
		name: 'ranking',
		component: () => import('../views/ranking/ranking.vue'),
		meta: {
			isShow: true,
			title: '品牌之家 品牌榜 | 十大品牌及生活服务排行榜大数据服务平台',
			content: {
				keywords: '品牌之家,排行榜,品牌,名牌,排名,品牌排行,品牌网,十大品牌,品牌百科,什么牌子好',
				description: '品牌之家（zgall.com）是一个综合性的品牌排行榜和生活知识平台，专注于提供详尽的品牌信息、生活指南和知识性文章。网站通过大数据、人工智能、GPT等一系列算法生成排行榜，帮助用户做出明智的品牌选择，从而提升人们的生活质量。'
			}
		}
	},
	{
		path: '/rankingMore',
		name: 'rankingMore',
		component: () => import('../views/rankingMore/rankingMore.vue'),
		meta: {
			isShow: true,
			title: '品牌之家 品牌榜 | 十大品牌及生活服务排行榜大数据服务平台',
			content: {
				keywords: '品牌之家,排行榜,品牌,名牌,排名,品牌排行,品牌网,十大品牌,品牌百科,什么牌子好',
				description: '品牌之家（zgall.com）是一个综合性的品牌排行榜和生活知识平台，专注于提供详尽的品牌信息、生活指南和知识性文章。网站通过大数据、人工智能、GPT等一系列算法生成排行榜，帮助用户做出明智的品牌选择，从而提升人们的生活质量。'
			}
		}
	},
	{
		path: '/search',
		name: 'search',
		component: () => import('../views/search/search.vue'),
		meta: {
			isShow: true,
			title: '品牌之家 品牌榜 | 十大品牌及生活服务排行榜大数据服务平台',
			content: {
				keywords: '品牌之家,排行榜,品牌,名牌,排名,品牌排行,品牌网,十大品牌,品牌百科,什么牌子好',
				description: '品牌之家（zgall.com）是一个综合性的品牌排行榜和生活知识平台，专注于提供详尽的品牌信息、生活指南和知识性文章。网站通过大数据、人工智能、GPT等一系列算法生成排行榜，帮助用户做出明智的品牌选择，从而提升人们的生活质量。'
			}
		}
	},
	{
		path: '/article',
		name: 'article',
		component: () => import('../views/article/article.vue'),
		meta: {
			isShow: true,
			title: '品牌百科',
			content: {
				keywords: '品牌之家,排行榜,品牌,名牌,排名,品牌排行,品牌网,十大品牌,品牌百科,什么牌子好',
				description: '品牌之家（zgall.com）是一个综合性的品牌排行榜和生活知识平台，专注于提供详尽的品牌信息、生活指南和知识性文章。网站通过大数据、人工智能、GPT等一系列算法生成排行榜，帮助用户做出明智的品牌选择，从而提升人们的生活质量。'
			}
		}
	},
	{
		path: '/articleDetail',
		name: 'articleDetail',
		component: () => import('../views/article/articleDetail.vue'),
		meta: {
			isShow: true,
			title: '品牌之家 品牌榜 | 十大品牌及生活服务排行榜大数据服务平台',
			content: {
				keywords: '品牌之家,排行榜,品牌,名牌,排名,品牌排行,品牌网,十大品牌,品牌百科,什么牌子好',
				description: '品牌之家（zgall.com）是一个综合性的品牌排行榜和生活知识平台，专注于提供详尽的品牌信息、生活指南和知识性文章。网站通过大数据、人工智能、GPT等一系列算法生成排行榜，帮助用户做出明智的品牌选择，从而提升人们的生活质量。'
			}
		}
	},
	{
		path: '/citiao',
		name: 'citiao',
		component: () => import('../views/article/citiao.vue'),
		meta: {
			isShow: true,
			title: '品牌之家 品牌榜 | 十大品牌及生活服务排行榜大数据服务平台',
			content: {
				keywords: '品牌之家,排行榜,品牌,名牌,排名,品牌排行,品牌网,十大品牌,品牌百科,什么牌子好',
				description: '品牌之家（zgall.com）是一个综合性的品牌排行榜和生活知识平台，专注于提供详尽的品牌信息、生活指南和知识性文章。网站通过大数据、人工智能、GPT等一系列算法生成排行榜，帮助用户做出明智的品牌选择，从而提升人们的生活质量。'
			}
		}
	},
	{
		path: '/brand/detail',
		name: 'brandDetail',
		component: () => import('../views/brand/detail.vue'),
		meta: {
			isShow: true,
			title: '品牌之家 品牌榜 | 十大品牌及生活服务排行榜大数据服务平台',
			content: {
				keywords: '品牌之家,排行榜,品牌,名牌,排名,品牌排行,品牌网,十大品牌,品牌百科,什么牌子好',
				description: '品牌之家（zgall.com）是一个综合性的品牌排行榜和生活知识平台，专注于提供详尽的品牌信息、生活指南和知识性文章。网站通过大数据、人工智能、GPT等一系列算法生成排行榜，帮助用户做出明智的品牌选择，从而提升人们的生活质量。'
			}
		}
	},
	{
		path: '/brand/detail2',
		name: 'detail2',
		component: () => import('../views/brand/detail2.vue'),
		meta: {
			isShow: true,
			title: '品牌之家 品牌榜 | 十大品牌及生活服务排行榜大数据服务平台',
			content: {
				keywords: '品牌之家,排行榜,品牌,名牌,排名,品牌排行,品牌网,十大品牌,品牌百科,什么牌子好',
				description: '品牌之家（zgall.com）是一个综合性的品牌排行榜和生活知识平台，专注于提供详尽的品牌信息、生活指南和知识性文章。网站通过大数据、人工智能、GPT等一系列算法生成排行榜，帮助用户做出明智的品牌选择，从而提升人们的生活质量。'
			}
		}
	},
	{
		path: '/about',
		name: 'about',
		component: () => import('../views/about/about.vue'),
		meta: {
			isShow: true,
			title: '品牌之家网站介绍',
			content: {
				keywords: '品牌之家,品牌',
				description: '品牌之家（zgall.com）是一个综合性的品牌排行榜和生活知识平台，专注于提供详尽的品牌信息、生活指南和知识性文章。网站通过大数据、人工智能、GPT等一系列算法生成排行榜，帮助用户做出明智的品牌选择，从而提升人们的生活质量。'
			}
		}
	},
	{
		path: '/statement',
		name: 'statement',
		component: () => import('../views/statement/statement.vue'),
		meta: {
			isShow: true,
			title: '品牌之家免责声明',
			content: {
				keywords: '品牌之家,排行榜,品牌,名牌,排名,品牌排行,品牌网,十大品牌,品牌百科,什么牌子好',
				description: '品牌之家（zgall.com）是一个综合性的品牌排行榜和生活知识平台，专注于提供详尽的品牌信息、生活指南和知识性文章。网站通过大数据、人工智能、GPT等一系列算法生成排行榜，帮助用户做出明智的品牌选择，从而提升人们的生活质量。'
			}
		}
	},
	{
		path: '/research',
		name: 'research',
		component: () => import('../views/research/research.vue'),
		meta: {
			isShow: true,
			title: '品牌之家研究院→品牌之家',
			content: {
				keywords: '品牌之家,研究院',
				description: '品牌之家（zgall.com）是一个综合性的品牌排行榜和生活知识平台，专注于提供详尽的品牌信息、生活指南和知识性文章。网站通过大数据、人工智能、GPT等一系列算法生成排行榜，帮助用户做出明智的品牌选择，从而提升人们的生活质量。'
			}
		}
	},
	{
		path: '/contact',
		name: 'contact',
		component: () => import('../views/contact/contact.vue'),
		meta: {
			isShow: true,
			title: '品牌之家联系方式',
			content: {
				keywords: '品牌之家,排行榜,品牌,名牌,排名,品牌排行,品牌网,十大品牌,品牌百科,什么牌子好',
				description: '品牌之家（zgall.com）是一个综合性的品牌排行榜和生活知识平台，专注于提供详尽的品牌信息、生活指南和知识性文章。网站通过大数据、人工智能、GPT等一系列算法生成排行榜，帮助用户做出明智的品牌选择，从而提升人们的生活质量。'
			}
		}
	},
]

const router = new VueRouter({
	mode: 'history',
	// mode: 'hash',
	routes
})
router.afterEach(() => {
  window.scrollTo(0, 0); // 立即滚动到页面顶部
})
router.beforeEach((to, from, next) => {
	// if (to.meta.content) {
	// 	let head = document.getElementsByTagName('head');
	// 	let meta = document.createElement('meta');
	// 	document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
	// 	document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
	// 	meta.content = to.meta.content;
	// 	head[0].appendChild(meta)
	// }

	if (to.meta.content) {
		const head = document.getElementsByTagName('head')[0];
		// 删除现有名称为 "keywords" 和 "description" 的 meta 标签
		const existingKeywordsMeta = document.querySelector('meta[name="keywords"]');
		if (existingKeywordsMeta) {
			existingKeywordsMeta.remove(); // 移除现有的关键词 meta 标签
		}
		const existingDescriptionMeta = document.querySelector('meta[name="description"]');
		if (existingDescriptionMeta) {
			existingDescriptionMeta.remove(); // 移除现有的描述 meta 标签
		}

		// 创建并设置新的关键词和描述 meta 标签
		const keywordsMeta = document.createElement('meta');
		keywordsMeta.setAttribute('name', 'keywords');
		keywordsMeta.setAttribute('content', to.meta.content.keywords);
		head.appendChild(keywordsMeta); // 添加新的关键词 meta 标签

		const descriptionMeta = document.createElement('meta');
		descriptionMeta.setAttribute('name', 'description');
		descriptionMeta.setAttribute('content', to.meta.content.description);
		head.appendChild(descriptionMeta); // 添加新的描述 meta 标签

		// 可选：如果需要，更新名为 "content" 的其他 meta 标签
		const contentMeta = document.querySelector('meta[name="content"]');
		if (contentMeta) {
			contentMeta.setAttribute('content', to.meta.content); // 更新 content 属性
		}
	}

	if (to.meta && to.meta.title) {
		document.title = to.meta.title;
	};
	next()
	
});
export default router